<template>
    <tutti-dialog
        ref="dialog"
        :buttons="buttons"
        :max-width="maxWidth || 400"
    >
        <template #title>
            <slot name="title" :params="params">
                <span v-html="title" />
            </slot>
        </template>
        <template #body>
            <slot name="body">
                <v-card-text v-html="body" />
            </slot>
        </template>
    </tutti-dialog>
</template>
<script>
import TuttiDialog from './TuttiDialog'
export default {
    components: {
        TuttiDialog
    },
    data: () => ({
        params: {},
    }),
    props: {
        maxWidth: { type: [Number, String] },
        title: { type: String },
        body: { type: String },
        cancellable: { type: Boolean, default: true }
    },
    computed: {
        buttons() {
            let ret = [];
            if(this.cancellable) {
                ret.push({
                    label: this.$t('ui.tuttiDialogAlert.buttons.cancel'),
                    attrs: {
                        color: 'grey darken-1',
                        text: true,
                    },
                    on: { click: this.cancel, }
                });
            }
            ret.push({
                label: this.$t('ui.tuttiDialogAlert.buttons.confirm'),
                attrs: {
                    color: 'indigo darken-1',
                    text: true,
                },
                on: { click: this.confirm, }
            })
            return ret;
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm', this.params);  
            this.hide();
        },
        cancel() {
            this.$emit('cancel');
            this.hide();
        },
        show(params) {
            this.params = params;
            this.$refs.dialog.show();
        },
        hide() { this.$refs.dialog.hide(); }
    }
}
</script>
